






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.payment_alert {
  &.is_rtl {
    flex-direction: row-reverse;
    .el-alert__closebtn {
      left: 15px;
      right: auto;
    }
  }
}
.input_with_rtl {
  .el-input__inner {
    text-align: right;
  }
}
.column_with_rtl {
  .el-checkbox {
    display: flex;
    flex-direction: row-reverse;
    gap: 3px;
    margin-bottom: 20px;
  }
}
.add_strategy_dialog_task_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
// .tabs_with_rtl {
//   .el-tabs__header {
//     display: flex !important;
//     justify-content: end !important;
//   }
// }
.form_with_rtl_pass {
  .el-form-item__label {
    width: 100%;
    text-align: right;
  }
  .el-form-item__content {
    .togglePassword {
      left: 12px;
      right: auto;
    }
    .el-input__inner {
      text-align: right;
    }
  }
}

.join_institution_with_rtl {
  text-align: right;
  .el-form-item__label {
    width: 100%;
  }
}
.tabs_with_rtl {
  .el-tabs__nav-scroll {
    direction: rtl;
  }
  .el-tabs__nav {
    float: right;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__item {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      height: 0;
      background-color: #409eff;
      z-index: 1;
      width: 90%;
      bottom: 0;
      right: 0;
    }
    &.is-active {
      &::before {
        height: 2px;
      }
    }
  }
}
